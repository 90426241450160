<template>
  <div class="page">
    <GainEntryExplain></GainEntryExplain>
  </div>
</template>
<script>
import GainEntryExplain from "@/components/rules/GainEntryExplain";
export default {
  components: {
    GainEntryExplain,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
